import {
  Card,
  Col,
  Collapse,
  Layout,
  List,
  Row,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import { useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { EyeTwoTone } from "@ant-design/icons";
const { Text } = Typography;

export default function TwoDCurrent() {
  const { window_height, user_id } = useSelector((state) => state.initial);
  const [loading, setLoading] = useState(false);
  const [todayValue, setTodayValue] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/v1/bet/twod_user_oingong/${user_id}`
      )
      .then(({ data }) => {
        setTodayValue(data);
      })
      .catch((error) => message.error(error.message))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function twodNumberHandler(bits) {
    let numbString = "";

    for (let i = 0; i < bits.length; i++) {
      if (bits.length > 4) {
        if (i < 3) {
          const bit = bits[i];
          if (bit) {
            numbString = numbString + bit.bit_number + ", ";
          }
        }
      } else {
        if (i < 3) {
          const bit = bits[i];
          if (bit) {
            numbString = numbString + bit.bit_number + ", ";
          }
        }
      }
    }

    return bits.length > 4 ? `${numbString} ...` : `${numbString}`;
  }

  function detailDisplay(bets) {
    return (
      <>
        <Row justify={"space-between"}>
          <Col span={6}>
            <Text strong>စဥ်</Text>
          </Col>
          <Col span={6}>
            <Text strong>နံပါတ်</Text>
          </Col>
          <Col span={6}>
            <Text strong>ပမာဏ</Text>
          </Col>
        </Row>
        {bets.map((bet, index) => (
          <Row
            justify={"space-between"}
            key={bet.id}
            style={{ marginTop: "8px", boxShadow: "0px 1px 0px 0px #8681819c" }}
          >
            <Col span={6}>
              <Text strong>{index + 1}</Text>
            </Col>
            <Col span={6}>
              <Text strong>{bet.bit_number}</Text>
            </Col>
            <Col span={6}>
              <Text strong>{bet.bit_amount}</Text>
            </Col>
          </Row>
        ))}
        <Row justify={"space-between"} style={{ marginTop: "8px" }}>
          <Col>
            <Text strong>Total</Text>
          </Col>
          <Col>
            <Text strong>
              {bets
                .map((value) => value.bit_amount)
                .reduce((a, b) => a + b)
                .toLocaleString("en-US")}{" "}
              MMK
            </Text>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader title={"ထီထိုးစာရင်း"} />
          <div
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              overflowX: "hidden",
              background: "#1D262F",
              scrollbarWidth: "none",
            }}
          >
            <Spin spinning={loading}>
              <Row
                gutter={[0, 24]}
                style={{
                  padding: "16px",
                }}
              >
                {todayValue.map((voucher) => (
                  <Col span={24} key={voucher.id}>
                    <Card title={null}>
                      <Row
                        align={"middle"}
                        gutter={[0, 8]}
                        justify={"space-between"}
                      >
                        <Col>
                          <Text strong>
                            {dayjs(voucher.created_at).format("DD-MM-YYYY")}
                          </Text>
                        </Col>
                        <Col>
                          <Text strong>{voucher.target_time}</Text>
                        </Col>
                        <Col span={24}></Col>
                        <Col>
                          <Text
                            style={{ color: "#34AF83", fontSize: "1.5rem" }}
                          >
                            {twodNumberHandler(voucher.twod_users)}
                          </Text>
                        </Col>
                        <Col strong>
                          <Text>
                            {voucher.twod_users
                              .map((value) => value.bit_amount)
                              .reduce((a, b) => a + b)
                              .toLocaleString("en-US")}{" "}
                            MMK
                          </Text>
                        </Col>
                        <Col span={24}></Col>
                        <Col span={24}>
                          <Collapse
                            bordered={false}
                            items={[
                              {
                                key: "1",
                                label: "အသေးစိတ် ကြည့်ရန် နှိပ်ပါ",
                                children: detailDisplay(voucher.twod_users),
                              },
                            ]}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
                <Col span={24} style={{ height: "80vh" }}></Col>
              </Row>{" "}
            </Spin>
          </div>
        </Layout.Content>
      </Layout>
    </div>
  );
}
