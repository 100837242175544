import {
  Button,
  Col,
  ConfigProvider,
  Layout,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import { useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import axios from "axios";

const { Text } = Typography;

export default function TwoDHome() {
  const { window_height, user_id, agent_code } = useSelector(
    (state) => state.initial
  );
  const [currentNumber, setCurrentNumber] = useState({
    set: "0.00",
    value: "0.00",
    twod: "--",
  });
  const [time, setTime] = useState(dayjs().format("DD-MM-YYYY HH:mm:ss"));
  const [loading, setLoading] = useState(false);
  const [todayValue, setTodayValue] = useState([]);

  const times = [
    { key: "11:00:00", time: "11 AM" },
    { key: "12:01:00", time: "12 PM" },
    { key: "15:00:00", time: "3 PM" },
    { key: "16:30:00", time: "4:30 PM" },
  ];

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/v1/bet/twod_section/${user_id}/${agent_code}`
      )
      .then(({ data }) => {
        console.log(data);
        let values = [];

        for (let i of times) {
          const target = data.find((value) => value.target_time === i.key);

          if (target) {
            values = [
              ...values,
              {
                time: i.time,
                set: target?.is_finish ? target.set : currentNumber.set,
                value: target?.is_finish ? target.value : currentNumber.value,
                number: target?.is_finish
                  ? parseInt(target.win_number) < 10
                    ? `0${parseInt(target.win_number)}`
                    : target.win_number
                  : "--",
                twod_agent: !!target ? target?.twod_agent : false,
              },
            ];
          } else {
            values = [
              ...values,
              {
                time: i.time,
                set: "00.00",
                value: "00.00",
                number: "--",
                twod_agent: false,
              },
            ];
          }
        }
        setTodayValue(values);
      })
      .catch((error) => message.error(error.message))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const TIME_INTERVAL = setInterval(() => {
      setTime(dayjs().format("DD-MM-YYYY HH:mm:ss"));
    }, 1000);

    return () => clearInterval(TIME_INTERVAL);
  }, []);

  useEffect(() => {
    const NUMBER_INTERVAL = setInterval(async () => {
      axios
        .get(process.env.REACT_APP_TWOD_API)
        .then(({ data }) => {
          setCurrentNumber(data.live);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 1000);

    return () => clearInterval(NUMBER_INTERVAL);
  }, []);

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader />
          <Row
            gutter={[24, 24]}
            style={{
              height: `${window_height - 60}px`,
              overflowY: "auto",
              padding: "16px",
              background: "#1D262F",
            }}
          >
            <Col span={24} style={{ height: "max-content" }}>
              <div
                style={{
                  width: "100%",
                  padding: "8px",
                  borderRadius: "50px",
                  background: "linear-gradient(135deg, #C24DB1,#8C38D9)",
                }}
              >
                <Row
                  justify={"center"}
                  style={{
                    width: "100%",
                    backgroundImage: 'url("/images/twodbackground.png")',
                    objectFit: "cover",
                  }}
                >
                  <Col style={{ paddingBottom: "24px" }}>
                    <Row>
                      <Col>
                        <div
                          style={{
                            marginTop: "6px",
                            marginRight: "4px",
                            height: "10px",
                            width: "10px",
                            background: "green",
                            borderRadius: "50%",
                          }}
                        ></div>
                      </Col>
                      <Col>
                        <Text strong style={{ fontSize: "0.8rem" }}>
                          Updated Result
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}></Col>
                  <Col style={{ paddingBottom: "24px" }}>
                    <Row
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "50%",
                        background: "linear-gradient(135deg, #F41C1C,#F9791C)",
                      }}
                      justify={"center"}
                      align={"middle"}
                    >
                      <Col>
                        <Text strong style={{ fontSize: "2.5rem" }}>
                          {currentNumber.twod}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}></Col>
                  <Col>
                    <Text strong style={{ fontSize: "0.8rem" }}>
                      {time}
                    </Text>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <div
                style={{
                  borderRadius: "20px",
                  background: "#181F25",
                  padding: "16px",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Link to="/twodcurrent">
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              colorPrimary: `linear-gradient(135deg, #C24DB1,#8C38D9)`,
                              colorPrimaryHover: `linear-gradient(135deg, #C24DB1,#8C38D9)`,
                              colorPrimaryActive: `linear-gradient(135deg, #C24DB1,#8C38D9)`,
                              lineWidth: 0,
                            },
                          },
                        }}
                      >
                        <Button
                          style={{ width: "100%" }}
                          type="primary"
                          size="large"
                        >
                          <Row justify={"space-around"}>
                            <Col>
                              <img
                                style={{ height: "26px" }}
                                src="/images/calendar.png"
                                alt="walletPlus"
                              />
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "0.8rem" }} strong>
                                ထီထိုးစာရင်း
                              </Text>
                            </Col>
                          </Row>
                        </Button>
                      </ConfigProvider>
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link to="/twodnew">
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              colorPrimary: `linear-gradient(135deg,#F4661C, #F9A21C)`,
                              colorPrimaryHover: `linear-gradient(135deg,#F4661C, #F9A21C)`,
                              colorPrimaryActive: `linear-gradient(135deg,#F4661C, #F9A21C)`,
                              lineWidth: 0,
                            },
                          },
                        }}
                      >
                        <Button
                          style={{ width: "100%" }}
                          type="primary"
                          size="large"
                        >
                          <Row justify={"space-around"}>
                            <Col>
                              <img
                                style={{
                                  height: "18px",
                                  marginRight: "4px",
                                  marginTop: "4px",
                                }}
                                src="/images/ticket.png"
                                alt="walletPlus"
                              />
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "0.8rem" }} strong>
                                ထီထိုးရန်
                              </Text>
                            </Col>
                          </Row>
                        </Button>
                      </ConfigProvider>
                    </Link>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <Row justify={"center"} gutter={[0, 12]}>
                <Col
                  style={{
                    background: "#181F25",
                    padding: "2px 12px",
                    borderRadius: "10px",
                  }}
                >
                  <Text strong style={{ fontWeight: "600" }}>
                    {dayjs().format("DD-MM-YYYY")}
                  </Text>
                </Col>
                {todayValue?.length > 0 && (
                  <>
                    {todayValue.map((timeNumber) => (
                      <Col
                        style={{
                          background: "#181F25",
                          padding: "8px 24px",
                          borderRadius: "16px",
                        }}
                        span={24}
                        key={timeNumber.time}
                      >
                        <Spin spinning={loading}>
                          {" "}
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                              <Text
                                style={{
                                  fontSize: "1.4rem",
                                  marginRight: "24px",
                                }}
                                strong
                              >
                                {timeNumber.time}
                              </Text>
                            </Col>
                            <Col>
                              {/* <Row>
                                <Col span={24}>
                                  <Text
                                    strong
                                    style={{
                                      fontWeight: "500",
                                      color: "#34AF83",
                                    }}
                                  >
                                    Set
                                  </Text>
                                  <Text strong> {timeNumber.set}</Text>
                                </Col>
                                <Col span={24}>
                                  <Text
                                    strong
                                    style={{
                                      fontWeight: "500",
                                      color: "#34AF83",
                                    }}
                                  >
                                    value
                                  </Text>
                                  <Text strong> {timeNumber.value}</Text>
                                </Col>
                              </Row> */}
                            </Col>
                            <Col>
                              <Text
                                style={{
                                  color: "#34AF83",
                                  fontSize: "1.8rem",
                                  fontWeight: "700",
                                }}
                              >
                                {timeNumber.number}
                              </Text>
                            </Col>
                          </Row>
                        </Spin>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </Col>
            <Col span={24} style={{ height: "100vh" }}></Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
}
