import {
  Button,
  Col,
  ConfigProvider,
  Layout,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import WalletHeader from "../component/walletHeader";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { updateInitial } from "../store/user";
import axios from "axios";

const { Text } = Typography;

export default function ThreeDHome() {
  const { window_height, user_id, agent_code, threeDselect } = useSelector(
    (state) => state.initial
  );
  const [loading, setLoading] = useState(false);
  const [todayValue, setTodayValue] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/v1/bet/three_section/${user_id}/${agent_code}`
      )
      .then(({ data }) => {
        setTodayValue(
          data.map((three) => ({
            number: three.win_number || "---",
            date: dayjs(three.target_date).format("DD-MM-YYYY"),
            is_finish: three.is_finish,
            bit_end_date: dayjs(three.bit_end_date).set("hours", 13),
            bit_start_date: three.bit_start_date,
            ongoing: three.threed_ongoing,
          }))
        );
      })
      .catch((error) => message.error(error.message))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div style={{ height: window_height, width: "100vw", overflow: "hidden" }}>
      <Layout>
        <Layout.Content>
          <WalletHeader />
          <Row
            gutter={[24, 24]}
            style={{
              height: `${window_height - 60}px`,
              padding: "16px",
              background: "#1D262F",
              overflowY: "auto",
            }}
          >
            <Col span={24}>
              <div
                style={{
                  borderRadius: "20px",
                  background: "#181F25",
                  padding: "16px",
                }}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Link to="/threedcurrent">
                      <ConfigProvider
                        theme={{
                          components: {
                            Button: {
                              colorPrimary: `linear-gradient(135deg, #C24DB1,#8C38D9)`,
                              colorPrimaryHover: `linear-gradient(135deg, #C24DB1,#8C38D9)`,
                              colorPrimaryActive: `linear-gradient(135deg, #C24DB1,#8C38D9)`,
                              lineWidth: 0,
                            },
                          },
                        }}
                      >
                        <Button
                          style={{ width: "100%" }}
                          type="primary"
                          size="large"
                        >
                          <Row justify={"space-around"}>
                            <Col>
                              <img
                                style={{ height: "26px" }}
                                src="/images/calendar.png"
                                alt="walletPlus"
                              />
                            </Col>
                            <Col>
                              <Text style={{ fontSize: "0.8rem" }} strong>
                                ထီထိုးစာရင်း
                              </Text>
                            </Col>
                          </Row>
                        </Button>
                      </ConfigProvider>
                    </Link>
                  </Col>
                  <Col span={12}>
                    {/* <Link to="/threedselect"> */}
                    <ConfigProvider
                      theme={{
                        components: {
                          Button: {
                            colorPrimary: `linear-gradient(135deg,#F4661C, #F9A21C)`,
                            colorPrimaryHover: `linear-gradient(135deg,#F4661C, #F9A21C)`,
                            colorPrimaryActive: `linear-gradient(135deg,#F4661C, #F9A21C)`,
                            lineWidth: 0,
                          },
                        },
                      }}
                    >
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        size="large"
                        onClick={() => {
                          console.log(todayValue);
                          const target = todayValue.find((data) => {
                            switch (true) {
                              case dayjs(data.bit_end_date).format('DD-MM-YYYY') === dayjs().format('DD-MM-YYYY') &&
                              dayjs().isBefore(data.bit_start_date) :
                              default:
                                return (
                                  dayjs().isAfter(data.bit_start_date) &&
                                  dayjs().isBefore(data.bit_end_date) &&
                                  !data.is_finish
                                );
                            }
                          });

                          console.log("target", target);

                          if (target) {
                            dispatch(
                              updateInitial({
                                select_3d_agent: target.ongoing,
                                threeDselect: [],
                              })
                            );
                            navigate("/threedselect");
                          }
                        }}
                      >
                        <Row justify={"space-around"}>
                          <Col>
                            <img
                              style={{
                                height: "18px",
                                marginRight: "4px",
                                marginTop: "4px",
                              }}
                              src="/images/ticket.png"
                              alt="walletPlus"
                            />
                          </Col>
                          <Col>
                            <Text style={{ fontSize: "0.8rem" }} strong>
                              ထီထိုးရန်
                            </Text>
                          </Col>
                        </Row>
                      </Button>
                    </ConfigProvider>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <Spin spinning={loading}>
                <Row gutter={[0, 12]}>
                  {todayValue.length > 0 && (
                    <>
                      {todayValue.map((timeNumber, index) => (
                        <Col
                          style={{
                            background: "#181F25",
                            padding: "8px 24px",
                            borderRadius: "16px",
                          }}
                          span={24}
                          key={index}
                        >
                          <Row justify={"space-between"} align={"middle"}>
                            <Col>
                              <Text
                                style={{
                                  fontSize: "1.2rem",
                                  fontWeight: "700",
                                }}
                              >
                                {timeNumber.date}
                              </Text>
                            </Col>
                            <Col>
                              <Text
                                style={{
                                  color: "#34AF83",
                                  fontSize: "1.8rem",
                                  fontWeight: "700",
                                }}
                              >
                                {timeNumber.number}
                              </Text>
                            </Col>
                          </Row>
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
              </Spin>
            </Col>
            <Col span={24} style={{ height: "100vh" }}></Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
}
